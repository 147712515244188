define('hadoop/listeners/featureflagged',[
    'hadoop/hadoop-wrapper',
    'hadoop/listeners/user-event-handlers',
    'hadoop/listeners/test-exposure-handlers',
    'adlhelpers',
    'domhelpers',
    'doneable'
], function(hadoop, userEventHandlers, testExposureHandlers, ADL, domHelpers, Doneable) {
    'use strict';

    // Module globals that are set in various functions at runtime
    var pageviewTestCookie = 'edap-d-l-pageview',
        genericTestCookie = 'edap-d-l-generic',
        namedTestCookie = 'edap-d-l-named',
        loadedRegex = /^[a-z]+[.](load|did[.]load)$/,
        genericRegex = /^generic[.].[a-z.]+/,
        sentReferrer = false,
        disabledLoggingTimeMinutes = 30,
        disabledLoggingCookieValue = '1',
        defaultListenerInitialized = false,
        value = 'value',
        pageViewTriggered = false,
        ignoredEvents,
        enablePageviewTest,
        enableGenericTest,
        enableNamedTest,
        enableLoadedTest;


    /**
     * Get the value of a cookie and return if the test is enabled or the defaultValue.
     * If the cookie doesn't exist, or the value isn't as expected, then it will return
     * the passed defaultValue.
     *
     * @param {String} [cookieName] - Name of cookie
     * @param {Boolean} [defaultValue] - Value to return if cookie doesn't exist
     * @returns {Boolean} - false: if the cookie is set (ie. the test is NOT enabled)
     *                      defaultValue: if the cookie is missing or incorrectly set
     */
    function testEnabledFromCookie(cookieName, defaultValue) {
        var cookieValue = domHelpers.getCookie(cookieName),
            ret;

        // If the cookie doesn't exist then we return the defalt value
        if (cookieValue === null) {
            return defaultValue;
        }

        // If the cookie is set then it means that we should NOT log and therefore should
        // return a false
        if (cookieValue === disabledLoggingCookieValue) {
            ret = false;
        } else {
            domHelpers.removeCookie(cookieName);
            ret = defaultValue;
        }

        return ret;
    }


    /**
     * Builds the list of events that should be ignored and NEVER sent to Hadoop.
     * NOTE: 'edap.error' will always be sent to Hadoop
     *
     * @param {Array} [eventList] - Array of event names that should never be sent to Hadoop
     */
    function setupEventsToIgnore(eventList) {
        var edapErrorIndex;

        // build the list of events to ignore
        ignoredEvents = eventList;

        // Look to see if 'edap.error' is in the list
        edapErrorIndex = ignoredEvents.indexOf('edap.error');

        // Prepare the ignoredEvents list by removing 'edap.error' since we want to load that to Hadoop
        if (edapErrorIndex > -1) {
            ignoredEvents.splice(edapErrorIndex, 1);
        }
    }


    /**
     * Checks if an eventName is in the list of eventNames to be ignored and NEVER sent to Hadoop
     *
     * @param {String} [eventName] - Name of an EDAP event to check
     * @returns {Boolean} - true if this event should be ignored and not sent to Hadoop (just based on eventName)
     *                      false if it should be processed
     */
    function isEventToIgnore(eventName) {
        return ignoredEvents.indexOf(eventName) > -1;
    }


    /**
     * Processes a Feature Flag that has persistent cookies associated with it.
     *
     * @param {String} [featureValue] - Value of the feature to process and assoicate with cookieName
     * @param {String} [cookieName] - Cookie to set/remove depending on the state of feature flags
     * @returns {Boolean} - If the feature flag is enabled or not
     */
    function processCookieBasedFeatureFlag(featureValue, cookieName) {
        var enabledFeatureValue = (featureValue === 1),
            featureCookieValue = domHelpers.getCookie(cookieName);

        // Only remove the cookie if it is set and the feature is enabled
        if (enabledFeatureValue && featureCookieValue) {
            domHelpers.removeCookie(cookieName);
        } else if (featureValue === 0) {
            domHelpers.setCookie(cookieName, disabledLoggingCookieValue, domHelpers.getHostname(), disabledLoggingTimeMinutes);
        }

        return enabledFeatureValue;
    }


    /**
     * Reads the features and sets some cached module globals with their status
     *
     * @param {Features} [features] - Features instance, like from edap.public.features
     */
    function readAndSetFeatureFlags(features) {
        // Caching the state of these tests for easier readability
        enablePageviewTest = processCookieBasedFeatureFlag(features.getFeatureProperty('edapFeatureHadoopPageviewLogging', value), pageviewTestCookie);
        enableGenericTest = processCookieBasedFeatureFlag(features.getFeatureProperty('edapFeatureHadoopGenericLogging', value), genericTestCookie);
        enableNamedTest = processCookieBasedFeatureFlag(features.getFeatureProperty('edapFeatureHadoopNamedLogging', value), namedTestCookie);
        enableLoadedTest = features.getFeatureProperty('edapFeatureHadoopLoadedLogging', value) === 1;
    }


    /**
     * The handler that is called for each event that is triggered.
     *
     * @param {String} [eventName] - Name of an EDAP event
     * @returns {Boolean} - true if this event should be logged to Hadoop,
     *                      false if it should not be logged to Hadoop
     */
    function shouldLogtoHadoop(eventName) {
        // Cache this so we don't have to process it twice in the condition below
        var isGeneric = genericRegex.test(eventName),
            isLoad = loadedRegex.test(eventName),
            isPageview = (eventName === 'pageview');

        // If it is a pageview and the pageview logging is enabled -OR-
        // If it is a generic event and generic logging is enabled -OR-
        // If it is a load event and load logging is enabled -OR-
        // If it is neither a pageview nor generic event nor load event and named event logging is enabled
        // then log the event to Hadoop
        if (
            (isPageview && enablePageviewTest) ||
            (isGeneric && enableGenericTest) ||
            (isLoad && enableLoadedTest) ||
            (!isPageview && !isGeneric && !isLoad && enableNamedTest)
        ) {
            return true;
        }

        return false;
    }


    /**
     * The function handles the logic on if a "page.referrer" event should be triggered.
     *
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     * @param {Object} [adl] - Data object passed into every listener from EDAP
     * @returns {Doneable} - Donable instance that is returned when all referrer logging processing
     *                       is complete
     */
    function handleReferrerLogging(edap, adl) {
        return new Doneable(function(done) {
            var referrer = domHelpers.getDocumentReferrer(),
                pageviewReferrerData = new ADL({}),
                pageReferrerEventName = 'page.referrer',
                fullClonedDataAdl = new ADL(adl.get()),
                propsToCopy = [
                    'visitorid',
                    'sessionid',
                    'pagehref',
                    'edapintegrationsversion',
                    'clienttimestamp',
                    'edapeventid',
                    'currentpageviewid',
                    'parentpageviewid',
                    'inauthid',
                    'clienttype',
                    'appenvironment',
                    'appname',
                    'appversion',
                    'monikerbrand',
                    'mpaasregion',
                    'pageflow',
                    'pagename',
                    'pagetype',
                    'publicuuid',
                    'sensitive',
                    'visitortype',
                    'analyticsbrand',
                    'exposedtests',
                    'fssessionurl',
                    'item5',
                    'pageurl',
                    'proctor',
                    'proctorpayload',
                    'requestmarker',
                    'testing',
                    'displaycurrency',
                    'displaylocale',
                    'preferredlanguage'
                ],
                i;

            // If we've already sent a referrer then we won't need to resend it again
            if (sentReferrer) {
                done();
                return;
            }

            // We need to process this referrer
            if (domHelpers.isCrossDomainReferral()) {
                // This needs to happen before the edap.trigger to avoid an infinite loop
                sentReferrer = true;

                // TECHDEBT: edap internal functions should not be called outside of edap but this whole thing is a hack and we need to do that because of using forceData below
                fullClonedDataAdl = new ADL(edap.internal.buildTriggerData(pageReferrerEventName, adl.get()));

                pageviewReferrerData.setIfNotSet('referrer', referrer);

                // TECHDEBT: filtering to only add specific fields to the page.referrer event's payload
                for (i = 0; i < propsToCopy.length; i++) {
                    pageviewReferrerData.setIfNotSet(propsToCopy[i], fullClonedDataAdl.isSetGet(propsToCopy[i]));
                }

                // Normally set via EDAP core, but when using "forceData" it has to be manually specificed
                pageviewReferrerData.setIfNotSet('edapeventname', pageReferrerEventName);

                // Special case where we are telling EDAP NOT to extend the ADL due to concerns about payload length
                // TECHDEBT: This should be removed when we can use sendBeacon()
                edap.trigger(pageReferrerEventName, pageviewReferrerData.get(), {forceData: true}).then(done);
            }
        }, {
            error: edap.error
        });
    }

    /**
     *
     * @param {Array} [doneableList] - Array of Doneable objects to be processed
     * @param {String} [eventName] - Event name sent along with the data to be sent to Hadoop
     * @param {ADL} [adl] - Instance of an ADL model
     */
    function sendFullEvent(doneableList, eventName, adl) {
        doneableList.push(
            hadoop.send(eventName, adl.get(), adl.isSetGet('sessionid'), adl.isSetGet('visitorid'))
        );
    }

    /**
     * The handler that is called for each event that is triggered.
     *
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     * @param {Object} [data] - Data object passed into every listener from EDAP
     * @param {Function} [setAsync] - Function to be called when the handler is async
     * @param {Function} [done] - Signals that the listener has completed. Can only be used when setAsync was executed previously
     */
    function handleEvent(edap, data, setAsync, done) {
        var adl = new ADL(data),
            eventName = adl.get('edapeventname'),
            doneableList = [],
            doneableOpts = {
                error: edap.error
            };


        setAsync();

        // Bail if this is an ignored Hadoop Event
        if (isEventToIgnore(eventName)) {
            done();
            return;
        }

        // Check the Feature Flags and see if we should log here
        if (shouldLogtoHadoop(eventName)) {
            // Some events require special handling and so we should route them accordingly here
            // NOTE: If we start adding more special case handlers then we should move then into an object
            //       and use it as a lookup table to route.
            switch (eventName) {
                case 'user.email.entered':
                    doneableList.push(
                        userEventHandlers.processUserEmailEntered(hadoop, edap, adl)
                    );
                    break;
                case 'test.exposure':
                    doneableList.push(
                        testExposureHandlers.processTestExposure(hadoop, edap, adl, pageViewTriggered)
                    );
                    break;
                case 'page.unload':
                    // Send the event to Hadoop like default, but don't fall through to pageview
                    pageViewTriggered = false;
                    sendFullEvent(doneableList, eventName, adl);
                    break;
                case 'pageview':
                    if (!pageViewTriggered) {
                        pageViewTriggered = true;
                        // Finalize and trigger test exposures once on a page view
                        doneableList.push(
                            testExposureHandlers.processTestExposure(hadoop, edap, adl, pageViewTriggered, true)
                        );
                    }
                    doneableList.push(
                        handleReferrerLogging(edap, adl)
                    );
                    // fall through to the default use case here so the event is sent to Hadoop after referrer processing
                default:
                    sendFullEvent(doneableList, eventName, adl);
            }

            Doneable.all(doneableList, doneableOpts).then(done);
        } else {
            done();
        }
    }


    /**
     * Adds the Hadoop event listener for "conditional" events that depend on the Feature Flags being
     * loaded before they are processed and potentially sent to Hadoop.
     *
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     */
    function doConditionalListenerSetup(edap) {
        if (enableLoadedTest) {
            edap.on('*', function(data, setAsync, done) {
                var adl = new ADL(data),
                    eventName = adl.get('edapeventname');

                if (loadedRegex.test(eventName)) {
                    handleEvent(edap, data, setAsync, done);
                }
            });
        }
    }


    /**
     * Adds the Hadoop event listener for events that are enabled-by-default to be logged to Hadoop.
     *
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     */
    function doDefaultListenerSetup(edap) {
        if (defaultListenerInitialized) {
            return;
        }

        // List of ignored events that we will not send to Hadoop regardless of the status of the feature flags
        setupEventsToIgnore(edap['public'].coreSupportedEvents);

        // If any of the default enabled tests are enabled then we should setup the listener
        if (enablePageviewTest || enableGenericTest || enableNamedTest) {
            edap.on('*', function(data, setAsync, done) {
                var adl = new ADL(data),
                    eventName = adl.get('edapeventname');

                // We don't want to process `.load/loaded` events here since they are considered
                // conditional from the edap/service response. If we did process them here then when
                // replay() is triggered post onLoad() this listener woudln't be re-executed since it
                // technically already processed the .load/.did.load events.
                // TECHDEBT: This isn't very clean but for the one off example we have it works and is pretty simple
                //           If we start having more of these conditions then we should consider refactoring
                if (!loadedRegex.test(eventName)) {
                    handleEvent(edap, data, setAsync, done);
                }
            });

            defaultListenerInitialized = true;
        }
    }


    /**
     * Function to setup listeners for this module
     *
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     */
    function setupListeners(edap) {
        defaultListenerInitialized = false;

        // Default Tests
        // Initialize the cached feature values from cookies or defaults
        enablePageviewTest = testEnabledFromCookie(pageviewTestCookie, true);
        enableGenericTest = testEnabledFromCookie(genericTestCookie, true);
        enableNamedTest = testEnabledFromCookie(namedTestCookie, true);

        // Conditional Tests
        enableLoadedTest = false;

        /**
         * We register an onLoad callback that will be executed when edap['public'].features.load() is called.
         */
        edap['public'].features.onLoad(function() {
            // Process the Feature Flags and set the module globals accordingly
            readAndSetFeatureFlags(edap['public'].features);

            // We call doDefaultListenerSetup() again to register the liseners in the case that logging was
            // disabled via cookie and is now enabled via the edap-service response
            doDefaultListenerSetup(edap);
            doConditionalListenerSetup(edap);
        });

        // Register the listeners that CAN be enabled by default
        doDefaultListenerSetup(edap);
    }

    return {
        setupListeners: setupListeners
    };
});

