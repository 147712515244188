define('ua/ua-config',[], function() {
    'use strict';

    return {

        /**
         *  The EDAP Scope name of the UA module
         */
        edapScopeName: 'ua',


        /**
         *  The <script> id to use when loading the UA script
         */
        scriptId: 'edap-ua',


        /**
         *  The Universal Analytics global namespace (ex. window.ua) that UA exposes
         */
        uaNS: window.edapOptions.uaNS || 'ua',


        /**
         * The name for our UA tracker (which will also prefix all `ua(...)` calls)
         */
        uaTrackerName: window.edapOptions.ns,


        /**
         * The URL to the Universal Analytics script
         */
        uaScriptUrl: '//www.google-analytics.com/analytics.js',


        /**
         * The URL to the Universal Analytics debug script
         */
        uaDebugScriptUrl: '//www.google-analytics.com/analytics_debug.js',


        /**
         *  The Universal Analytics cookie name
         */
        uaCookieName: '_ga',


        /**
         *  Cross-domain tracking query parameter
         */
        uaCrossDomainParam: '_haga',


        /**
         *  The persistent cookie name for entry info
         */
        entryInfoCookie: '__utmuaepi',


        /**
         *  The order of the cookie data in the entryInfoCookie
         */
        entryInfoCookieFormat: ['entrypagename', 'entrypagetype'],


        /**
         * The maximum number of bytes allowed in a UA Custom Dimension
         */
        maxDimensionBytes: 150,


        /**
         *  Config of monikerbrand/analyticsbrand to config data per brand.
         *
         *  Config data format is:
         *    [UA ProfileId, Cookie Domain, Cookie Path, Profile Regex, Profile Regex Weight, Skip Profile Regex]
         *
         *  UA ProfileId - The UA profile id, ex. 'UA-22402634-2'
         *  Cookie Domain - The Cookie domain to use for the UA cookie. If null, then it will use the current hostname.
         *  Cookie Path - Almost always "/", unless your site segments UA profiles on different paths.
         *                ex. bedandbreakfast.com/fr is tracked by a different profile that bedandbreakfast/de
         *  Profile Regex - Only to be used if the Cookie Domain cannot be used to differentitate profiles. Should
         *                  be setup to match the entire ^ to $ path including query params
         *  Profile Weight - Only to be used if the Profile Regex is used. This overrides the Cookie Domain default
         *                   weight based on the number of "." segments. Weight is used to determine the most specific
         *                   profile matching an href and ultimately the highest weight wins. If two profiles match
         *                   and have the same weight then an error will be thrown, so adjust your Profile Regex
         *                   accordingly.
         *  Skip Profile Regex - Use this for sites that do not have a unique href that can be matched via regex. This site will
         *                       not be processed in "getMonikerBrandByHref" and can only be set using ADL.analyticsbrand. Currently
         *                       this option is used for the hasp_(v12|escapia) sites since they have some weird URLs.
         *
         *  NOTE: At least one of Cookie Domain or Profile Regex must be set to a non-null in order to properly match a site.
         */
        supportedSites: {
            abritel: ['UA-1010473-2', 'abritel.fr', '/', null, null],
            admin_homeaway_us: ['UA-750552-20', null, '/', /^https?:[/][/]admin[^.]*[.]homeaway[.]com(:\d+)?[/]?.*$/, 3],
            asia_homeaway_us: ['UA-750552-15', null, '/', /^https?:[/][/]asia[^.]*[.]homeaway[.]com(:\d+)?[/]?.*$/, 3],
            bookabach: ['UA-616929-17', null, '/', /^https?:[/][/](www[.])?([^.]*[.])?bookabach[.](local|dev|co[.]nz)(:\d+)?[/]?.*$/, 3],
            bnb: ['UA-210916-1', 'bedandbreakfast.com', '/', null, null],
            bnb_de: ['UA-210916-24', 'de.bedandbreakfast.com', '/', null, null],
            bnb_es: ['UA-210916-15', 'es.bedandbreakfast.com', '/', null, null],
            bnb_fr: ['UA-210916-14', 'fr.bedandbreakfast.com', '/', null, null],
            bnb_it: ['UA-210916-16', 'it.bedandbreakfast.com', '/', null, null],
            expedia_abritel: ['UA-1010473-7', null, '/', /^https?:[/][/]expedia[^.]*[.]abritel[.]fr(:\d+)?[/]?.*$/, 3],
            expedia_homeaway_de: ['UA-695211-4', null, '/', /^https?:[/][/]expedia[^.]*[.]fewo-direkt[.]de(:\d+)?[/]?.*$/, 3],
            expedia_homeaway_it: ['UA-6702181-3', null, '/', /^https?:[/][/]expedia[^.]*[.]homeaway[.]it(:\d+)?[/]?.*$/, 3],
            expedia_homeaway_us: ['UA-750552-12', null, '/', /^https?:[/][/]expedia[^.]*[.]homeaway[.]com(:\d+)?[/]?.*$/, 3],
            expediagroup: ['UA-143562396-1', 'expediagroup.com', '/', null, null],
            hasp_escapia: ['UA-72367836-1', null, '/', null, null, true], // hasp has weird URLs per environment so default cookie hostname and skip regex
            hasp_v12: ['UA-72367836-2', null, '/', null, null, true], // hasp has weird URLs per environment so default cookie hostname and skip regex
            homeaway_ar: ['UA-26926207-8', 'homeaway.com.ar', '/', null, null],
            homeaway_asia: ['UA-26926207-8', 'homeaway.asia', '/', null, null],
            homeaway_at: ['UA-25375606-1', 'homeaway.at', '/', null, null],
            homeaway_au: ['UA-20666200-1', 'homeaway.com.au', '/', null, null],
            homeaway_br: ['UA-16267529-1', 'aluguetemporada.com.br', '/', null, null],
            homeaway_ca: ['UA-20662353-1', 'homeaway.ca', '/', null, null],
            homeaway_cafr: ['UA-20659398-1', 'fr.homeaway.ca', '/', null, null],
            homeaway_cl: ['UA-26926207-8', 'homeaway.cl', '/', null, null],
            homeaway_cn: ['UA-26926207-8', 'homeaway.com.cn', '/', null, null],
            homeaway_co: ['UA-26926207-8', 'homeaway.com.co', '/', null, null],
            homeaway_de: ['UA-695211-1', 'fewo-direkt.de', '/', null, null],
            homeaway_dk: ['UA-6698428-1', 'homeaway.dk', '/', null, null],
            homeaway_ec: ['UA-26926207-8', 'homeaway.ec', '/', null, null],
            homeaway_es: ['UA-6515617-1', 'homeaway.es', '/', null, null],
            homeaway_fi: ['UA-8405990-1', 'homeaway.fi', '/', null, null],
            homeaway_gr: ['UA-57529940-1', 'homeaway.gr', '/', null, null],
            homeaway_hk: ['UA-26926207-8', 'homeaway.hk', '/', null, null],
            homeaway_id: ['UA-26926207-8', 'homeaway.co.id', '/', null, null],
            homeaway_in: ['UA-26926207-8', 'homeaway.co.in', '/', null, null],
            homeaway_it: ['UA-6702181-1', 'homeaway.it', '/', null, null],
            homeaway_jp: ['UA-26926207-8', 'homeaway.jp', '/', null, null],
            homeaway_kr: ['UA-26926207-8', 'homeaway.co.kr', '/', null, null],
            homeaway_lk: ['UA-26926207-8', 'homeaway.lk', '/', null, null],
            homeaway_mx: ['UA-20659393-1', 'homeaway.com.mx', '/', null, null],
            homeaway_my: ['UA-26926207-8', 'homeaway.com.my', '/', null, null],
            homeaway_nl: ['UA-6702415-1', 'homeaway.nl', '/', null, null],
            homeaway_nz: ['UA-35336958-1', 'homeaway.co.nz', '/', null, null],
            homeaway_no: ['UA-6737728-1', 'homeaway.no', '/', null, null],
            homeaway_pe: ['UA-26926207-8', 'homeaway.com.pe', '/', null, null],
            homeaway_ph: ['UA-26926207-8', 'homeaway.com.ph', '/', null, null],
            homeaway_pl: ['UA-62763815-1', 'homeaway.pl', '/', null, null],
            homeaway_pt: ['UA-6698180-1', 'homeaway.pt', '/', null, null],
            homeaway_ru: ['UA-26926207-8', 'homeaway.ru', '/', null, null],
            homeaway_se: ['UA-6698412-1', 'homeaway.se', '/', null, null],
            homeaway_sg: ['UA-26926207-8', 'homeaway.com.sg', '/', null, null],
            homeaway_th: ['UA-26926207-8', 'homeaway.co.th', '/', null, null],
            homeaway_tw: ['UA-26926207-8', 'homeaway.tw', '/', null, null],
            homeaway_uk: ['UA-5649527-1', 'homeaway.co.uk', '/', null, null],
            homeaway_us: ['UA-750552-1', 'homeaway.com', '/', null, null],
            homeaway_uy: ['UA-26926207-8', 'homeaway.com.uy', '/', null, null],
            homeaway_vn: ['UA-26926207-8', 'homeaway.com.vn', '/', null, null],
            homelidays_es: ['UA-10200033-4', 'homelidays.es', '/', null, null],
            homelidays_fr: ['UA-394012-1', 'homelidays.com', '/', null, null],
            homelidays_it: ['UA-394012-1', 'homelidays.it', '/', null, null],
            odhr: ['UA-3854735-1', 'ownersdirect.co.uk', '/', null, null],
            rezovation: ['UA-210916-3', 'rezovation.com', '/', null, null],
            stayz: ['UA-6596349-1', 'stayz.com.au', '/', null, null],
            toprural: ['UA-17472105-1', 'toprural.com', '/', null, null],
            toprural_cat: ['UA-17472502-1', 'toprural.cat', '/', null, null],
            toprural_fr: ['UA-17471817-1', 'toprural.fr', '/', null, null],
            toprural_it: ['UA-17471658-1', 'toprural.it', '/', null, null],
            toprural_pt: ['UA-17471555-1', 'toprural.pt', '/', null, null],
            travelmob: ['UA-26926207-8', 'travelmob.com', '/', null, null],
            vr: ['UA-2376682-1', 'vacationrentals.com', '/', null, null],
            vrbo: ['UA-188611-1', 'vrbo.com', '/', null, null],

            // add these for local development - don't route to a UA profile
            localhost: ['DEV', 'localhost', '/', null, null],
            '0.0.0.0': ['DEV', '0.0.0.0', '/', null, null],
            '127.0.0.1': ['DEV', '127.0.0.1', '/', null, null],
            github_pages: ['DEV', null, '/', /^(https?:[/][/])?github\.homeawaycorp\.com\/pages.*$/i, 1],
            homeaway_machine_name: ['DEV', null, '/', /^(https?:[/][/])?ha(00)?(\d{4})(\.wvrgroup\.internal)?(:\d+)?[/]?.*$/i, 1],     // e.g. ha003258.wvrgroup.internal:8000/
            homeaway_machine_serial_number: ['DEV', null, '/', /^(https?:[/][/])?C02.*(\.wvrgroup\.internal)?(:\d+)?[/]?.*$/i, 1] // begins with serial number e.g. C02S8793G8WP.wvrgoup.internal:8000/
        },


        /**
         *  ADL.pagename to VPV mapping
         */
        pagenameToVPV: {
            'ha: addto: add-on': '/cart/addto/add-on',
            'ha: addto: confirm': '/cart/addto/confirm',
            'ha: addto: cross sell': '/cart/addto/cross-sell',
            'ha: addto: payment': '/cart/addto/payment',
            'ha: addto: search position': '/cart/addto/search-position',
            'ha: addto: select subscription to upgrade': '/cart/addto/select-subscription-to-upgrade',
            'ha: list your property: add-on': '/cart/signup/add-on',
            'ha: list your property: confirm': '/cart/signup/confirm',
            'ha: list your property: cross sell': '/cart/signup/cross-sell',
            'ha: list your property: get help learn more': '/cart/signup/get-help-learn-more',
            'ha: list your property: get help referral': '/cart/signup/get-help-referral',
            'ha: list your property: payment': '/cart/signup/payment',
            'ha: list your property: pm admin tool': '/cart/signup/pm-admin-tool',
            'ha: list your property: pm directory entry': '/cart/signup/pm-directory-entry',
            'ha: list your property: ppb learn more': '/cart/signup/ppb-learn-more',
            'ha: list your property: ppb registration form': '/cart/signup/ppb-registration-form',
            'ha: list your property: pps learn more': '/cart/signup/pps-learn-more',
            'ha: list your property: pps registration form': '/cart/signup/pps-registration-form',
            'ha: list your property: register': '/cart/signup/register',
            'ha: list your property: search position': '/cart/signup/search-position',
            'ha: new property: add-on': '/cart/new-property/add-on',
            'ha: new property: confirm': '/cart/new-property/confirm',
            'ha: new property: cross sell': '/cart/new-property/cross-sell',
            'ha: new property: cyp': '/cart/new-property/cyp',
            'ha: new property: payment': '/cart/new-property/payment',
            'ha: new property: search position': '/cart/new-property/search-position',
            'ha: one step renewal: confirm': '/cart/one-step-renewal/confirm',
            'ha: one step renewal: payment': '/cart/one-step-renewal/payment',
            'ha: ppb new property: account info': '/cart/ppb-new-property/account-info',
            'ha: ppb new property:register': '/cart/ppb-new-property/register',
            'ha: ppb new property:rental policy': '/cart/ppb-new-property/rental-policy',
            'ha: ppb renewal: account info': '/cart/ppb-renewal/account-info',
            'ha: ppb renewal: cyp': '/cart/ppb-renewal/cyp',
            'ha: ppb renewal:register': '/cart/ppb-renewal/register',
            'ha: ppb renewal:rental policy': '/cart/ppb-renewal/rental-policy',
            'ha: ppb signup: account info': '/cart/ppb-signup/account-info',
            'ha: ppb signup: basic listing description': '/cart/ppb-signup/basic-listing-description',
            'ha: ppb signup: basic listing photos': '/cart/ppb-signup/basic-listing-photos',
            'ha: ppb signup: lyp': '/cart/ppb-signup/lyp',
            'ha: ppb signup:listingbuilder where': '/cart/ppb-signup/listingbuilder-where',
            'ha: ppb signup:register': '/cart/ppb-signup/register',
            'ha: ppb signup:rental policy': '/cart/ppb-signup/rental-policy',
            'ha: renewal: add-on': '/cart/renewal/add-on',
            'ha: renewal: confirm': '/cart/renewal/confirm',
            'ha: renewal: cross sell': '/cart/renewal/cross-sell',
            'ha: renewal: payment': '/cart/renewal/payment',
            'ha: renewal: search position': '/cart/renewal/search-position',
            'ha: renewal: select listings to renew': '/cart/renewal/select-listings-to-renew',
            'ha: special offer: confirm': '/cart/special-offer/confirm',
            'ha: special offer: payment': '/cart/special-offer/payment',
            'haolb : traveler checkout page': '/traveler/booking/checkout',
            'haolb : traveler confirmation page': '/traveler/booking/confirmation',
            'haolb : traveler payment page': '/traveler/booking/payment',
            'haolb : traveler rules policies page': '/traveler/booking/rulespolicies',
            'haolb : traveler trip protection page': '/traveler/booking/tripprotection',
            'owner login': '/owner/login/login',
            'payment: offline traveler checkout page': '/traveler/bookingpayment/offlinecheckout',
            'payment: offline traveler payments confirmation page': '/traveler/bookingpayment/offlineconfirmation',
            'payment: traveler payments confirmation page': '/traveler/bookingpayment/confirmation',
            'payment: traveler rules policies page': '/traveler/bookingpayment/rulespolicies',
            'payment: traveler trip protection page': '/traveler/bookingpayment/tripprotection',
            'reviews:submitted': '/traveler/review/submitted',
            'reviews:write': '/traveler/review/write',
            'traveler login': '/traveler/login/login',
            'traveler payment protection confirmation': '/traveler/paymentprotection/confirmation',
            'traveler payment protection form': '/traveler/paymentprotection/form',
            'vas: traveler checkout page': '/traveler/bookingvas/checkout',
            'vas: traveler confirmation page': '/traveler/bookingvas/confirmation',
            // TECHDEBT: This is the old Reviews pagename that can be removed once the new Review app goes live
            'write review': '/traveler/review/writereview'
        },


        /**
         *  Object of VPV's that are programmatically set
         */
        staticVPVs: {
            'inquirymodal': '/traveler/property/contactownermodal',
            'bookingmodal': '/traveler/property/bookingmodal',
            'quoteattempt': '/traveler/property/quoteattempt',
            'quotesuccess': '/traveler/property/quotesuccess',
            'quotefailure': '/traveler/property/quotefailure',
            'reviewsubmit': '/traveler/review/submitreview',
            'owner registration': '/owner/cart/registration',
            'inquiry confirmation': '/traveler/inquiry/submitted',
            'booking confirmation': '/traveler/booking/success'
        },


        /**
         *  Custom Dimension extractor config. In the format:
         *    dimensionName : extractor
         *
         *  dimensionName - Either a name or an integer. If it is an integer then it is assumed to be shorthand
         *                  for a Custom Dimension. If it is a string then it is assumed to be a UA parameter name.
         *  extractor - Can be either a String or a Function. If it is a String then it is assumed to be an
         *              ADL property, and it will be set if the ADL.isSet(key) return true.
         *              If it is a Function then the value will be set if ADL.isSet() of the return value of the function
         *              return true. The function prototype should look like:
         *                function(adl, ua, tracker) {...}
         *
         *              adl - ADLHelpers instance of the ADL data
         *              ua - UA-Wrapper instance
         *              tracker - UA tracker instance (for getting UA-specific data)
         */
        dimensionExtractors: {
            appName: 'appname',
            appVersion: 'appversion',
            1: function(adl, ua, tracker) {
                return ua.extractors.getVisitorId(adl, ua, tracker);
            },
            2: 'pagetype',
            3: 'pagename',
            4: 'geonode',
            5: function(adl, ua, tracker) {
                return ua.extractors.getEntryPageName(adl, ua, tracker);
            },
            6: 'visitortype',
            7: function(adl, ua, tracker) {
                return ua.extractors.getBrand(adl, ua, tracker);
            },
            8: function(adl, ua, tracker) {
                return ua.extractors.getUrl(adl, ua, tracker);
            },
            9: function(adl, ua, tracker) {
                return ua.extractors.getPPC(adl, ua, tracker);
            },
            // 10: open
            11: 'geonodelevel',
            12: 'inquiryid',
            13: 'eventquoteid', // note: This is not defined in the ADL (but should be in trigger data object)
            14: 'propertyid',
            // 15: open
            16: function(adl) {
                return adl.getListingIds();
            },
            17: 'reservationid',
            // 18: merchandizing based on AdSense click
            19: function(adl, ua, tracker) {
                return ua.extractors.getICID(adl, ua, tracker);
            },
            20: function(adl, ua, tracker) {
                return ua.extractors.getEntryPageType(adl, ua, tracker);
            },
            // 21: Canonical ????
            // 22: Lead ID ????
            23: 'registrationtype',
            24: function(adl, ua, tracker) {
                return ua.extractors.getSearchTerm(adl, ua, tracker);
            },
            25: 'numresults',
            26: 'publicuuid',
            27: function(adl, ua, tracker) {
                return ua.extractors.getCalendarLastUpdated(adl, ua, tracker);
            },
            // 28: Set in ua.getProctorDimension()
            29: 'searchviewtopdp',
            // 30: Set in ua.getProctorDimension()
            // 31: Not set
            32: 'searchtype',
            33: 'sort',
            34: 'sortproctor',
            35: function(adl, ua, tracker) {
                return ua.extractors.getPDPEntryDate(adl, ua, tracker);
            },
            // 36: Jahia/Marketing Optimizely Tests
            // 37: Ecommerce Optimizely Tests
            // 38: Set in ua.getProctorDimension()
            // 39: Cart Opimizely Tests
            40: 'ownerpublicuuid',
            41: 'searchview',
            42: 'inquirydetails',
            43: 'testattributes',
            // 44: Set in ua.getProctorDimension()
            // 45: Traveler Optimizely Tests
            // 46: Set in ua.getProctorDimension()
            // 47: Cart Optimizely Tests
            // 48: Set in ua.getProctorDimension()
            // 49: Dash Optimizely Tests
            // 50: Jahia Optimizely Tests

            // Property Details breakouts
            51: 'item1',  // Listing type ('cross sold', 'supersized', ...)
            52: 'propertytype',
            53: 'onlinepayments',
            54: 'translated',
            55: 'numreviews',
            56: 'multiunit',
            57: 'item2',
            58: 'responsivenesstime',
            59: 'responsivenessrate',
            60: 'item4',  // Owner Type
            61: 'item3', // PPS/PPB/PPL
            62: 'pmid',
            63: 'servicefee',

            81: function(adl, ua, tracker) {
                return ua.extractors.getICID(adl, ua, tracker);
            },
            82: function(adl, ua) {
                return ua.extractors.getEDAPIntegrationsVersion(adl, ua);
            },
            83: 'lengthofstay',
            84: 'paymentmethod',
            85: function(adl, ua) {
                return ua.extractors.getTripDates(adl);
            },
            86: 'refinebucket',
            87: 'refinevalue',
            88: function(adl, ua) {
                return ua.extractors.getRangeSet(adl, ua, 'minsleeps', 'maxsleeps');
            },
            89: function(adl, ua) {
                return ua.extractors.getRangeSet(adl, ua, 'minprice', 'maxprice');
            },
            90: function(adl, ua) {
                return ua.extractors.getRangeSet(adl, ua, 'minbeds', 'maxbeds');
            },
            91: function(adl, ua) {
                return ua.extractors.getRangeSet(adl, ua, 'minbaths', 'maxbaths');
            },
            92: 'locationtype',
            93: 'lbsuuid',
            94: function(adl, ua) {
                return ua.extractors.getHaVisitorID();
            },
            95: function(adl, ua) {
                return ua.extractors.getHaSessionID();
            },
            // 96: Set in ua.getProctorDimension()
            97: 'proctor', // page-level proctor variable
            98: 'paymentsplit',
            // 99: Set in ua.getProctorDimension()
            100: 'mpaasregion',
            101: function(adl, ua) {
                return ua.extractors.getNumGuests(adl);
            },
            // 102: Set in ua.getProctorDimension()
            106: 'hearttype',
            107: 'heartid',
            108: 'ownertype',
            109: 'edapeventid',
            110: 'edapeventname',
            111: 'currentpageviewid',
            112: 'parentpageviewid',
            113: 'displayprice',
            114: 'fullorderrentalrate',
            115: 'displayquotetotal',
            116: 'partnerfeesamount',
            117: 'numpartnerfees',
            118: 'servicefeeamount',
            119: 'ordertax',
            120: 'rdd',
            121: 'pdp',
            122: function(adl, ua) {
                return ua.extractors.getCurrency(adl);
            }
        }

    };
});

