define('ua/ua-wrapper',[
    'edap',
    'adlhelpers',
    'domhelpers',
    'doneable',
    'debughelpers',
    'ua/dimension-extractors',
    'ua/ua-config',
    'edaphelpers'
], function(edap, ADL, domHelpers, Doneable, debugHelpers, extractors, uaConfig, edapHelpers) {
    'use strict';

    function UA() {
        var uaTracker;

        /**
         *  The Custom Dimesnion extractor functions object
         */
        this.extractors = extractors;


        /**
         *  The UA Scope Edap object
         *
         *  Defaulted to edap and the ua scope is set up in init()
         */
        this.uaEdap = edap;


        /**
         *  The global ADL object
         */
        this.globalADL = new ADL();


        /**
         *  Extracts the clientId from a UA "_ga" cookie value
         *
         *  @example
         *      var _ga = 'GA1.3.1240662337.1454057530';
         *      var clientId = parseClientIdFromUACookie(_ga);
         *      clientId === '1240662337.1454057530' //true
         *
         *  @param {String} [cookie] - UA cookie value (ex. 'GA1.3.1240662337.1454057530')
         *  @returns {String} - Client ID extracted from the UA cookie. (ex. '1240662337.1454057530') or null
         */
        this.parseClientIdFromUACookie = function(cookie) {
            var cookieParts;

            // Cookie has to be a string, otherwise we can't parse the clientId from it
            if (typeof cookie !== 'string') {
                return null;
            }

            cookieParts = cookie.split('.');

            // Validate the cookie format before we extract the clientId
            if (cookieParts.length !== 4 || cookie.indexOf('GA') !== 0) {
                this.uaEdap.error('Unrecognized UA cookie format: ' + cookie);
                return null;
            }

            // Return the clientId
            return [cookieParts[2], cookieParts[3]].join('.');
        };


        /**
         *  Created a log entry into the EDAP UA Scope Data object for debugging UA actions
         *
         *  @example
         *    ua.logUAAction('Event', ['send', 'event', 'category', 'action', 'label', value]);
         *
         *  @param {String} [type] - The type of UA action to log
         *  @param {Object} [data] - Any associated data to log
         */
        this.logUAAction = function(type, data) {
            var dataLog;

            if (this.uaEdap === null) {
                edap.error('Could not log UA event: ' + [type, data].join(','));
                return;
            }

            dataLog = this.uaEdap.getData();

            dataLog.uaevents = dataLog.uaevents || [];
            dataLog.uaevents.push([type, data]);
        };


        /**
         *  Wrapper for making the Custom Dimension string
         *
         *  @example
         *    var dim = ua.dimName(1);
         *
         *  @param {Number} [id] - The numeric ID of a Custom Dimension
         *  @returns {String} - Any associated data to log
         */
        this.dimName = function(id) {
            return 'dimension' + id;
        };


        /**
         *  Helper function for determining whether a value should be a "dimension" or just
         *  a passthrough.
         *
         *  @example
         *    var dim = ua.dimName(1);  // dim='dimension1'
         *    var dim = ua.dimName('appName');  // dim='appName'
         *
         *  @param {Object} [id] - Value to check if it should be a dimension
         *  @returns {Object} - If the value is parseInt()-able then it is considered a "dimension"
         *                      otherwise it will just return the same value
         */
        this.determineDimName = function(id) {
            var numId = parseInt(id, 10);

            // If you pass through a non-number-like value then just return it
            if (isNaN(numId)) {
                return id;
            }

            return this.dimName(numId);
        };


        /**
         *  Wrapper for making the Custom Metric string
         *
         *  @example
         *    var metric = ua.metricName(1);
         *
         *  @param {Number} [id] - The numberic ID of a Custom Metric
         *  @returns {String} - Any associated data to log
         */
        this.metricName = function(id) {
            return 'metric' + id;
        };


        /**
         *  Getter for the ua-config module data
         *
         *  @example
         *    var sid = ua.getConfig();
         *
         *  @returns {Object} - Config data object
         */
        this.getConfig = function() {
            return uaConfig;
        };


        /**
         *  Getter for the global UA instance
         *
         *  @example
         *    var ua = ua.getUA();
         *
         *  @returns {Object} - Global UA instance
         */
        this.getUA = function() {
            return domHelpers.getWindow()[this.getConfig().uaNS];
        };


        /**
         * Truncates an ASCII/UTF-8 string to a given number of bytes
         *
         * truncation algorithm based on:
         * http://stackoverflow.com/questions/1515884/using-javascript-to-truncate-text-to-a-certain-size-8-kb
         *
         * @param  {String} str The string to truncate
         * @param  {Number} n   The number of bytes to truncate to
         * @return {String}     The truncated version of str
         */
        this.truncateDimensionByUTF8Bytes = function(str, n) {
            var found = false,
                strBytes,
                res = str;

            function toBytesUTF8(chars) {
                return unescape(encodeURIComponent(chars));
            }
            function fromBytesUTF8(bytes) {
                return decodeURIComponent(escape(bytes));
            }

            strBytes = toBytesUTF8(str).substring(0, n);

            // short-circut if the original string is good
            if (strBytes.length < n) {
                return str;
            }

            while (!found) {
                try {
                    res = fromBytesUTF8(strBytes);

                    // will only get here if no URIError from dividing chars
                    found = true;
                } catch (e) {
                }
                strBytes = strBytes.substring(0, strBytes.length - 1);
            }

            return res;
        };


        /**
         * Handles prefixing the name of the UA function with the correct tracker name
         *
         * @param  {String} uaFuncName The UA function we want to call on the UA object (e.g. "send", "set", etc.)
         * @return {String}            The UA function name prefixed by the tracker name
         */
        this.buildUaFunctionName = function(uaFuncName) {
            return [
                this.getConfig().uaTrackerName,
                uaFuncName
            ].join('.');
        };


        /**
         * Logs an error message for truncated custom dimensions
         *
         * @param  {Array} truncationsObjs An array of objects containing truncation info with keys 'dimName', 'originalValue', and 'truncatedValue'
         */
        this.logTruncationErrors = function(truncationsObjs) {
            var config = this.getConfig(),
                maxDimensionBytes = config.maxDimensionBytes,
                skipDimensions = {
                    'dimension8': true,
                    'dimension97': true // FIXME: this should be removed when we get rid of the split proctor vars
                },
                truncatedDimensions = [],
                currentDim,
                i;

            for (i = 0; i < truncationsObjs.length; i++) {
                currentDim = truncationsObjs[i].dimName;

                // don't log truncation of the URL or the second proctor dimension
                if (!skipDimensions[currentDim]) {
                    truncatedDimensions.push(currentDim);
                }
            }

            // log any errors we found
            if (truncatedDimensions.length > 0) {
                this.uaEdap.error([
                    'UA: the following Custom Dimensions were truncated because they were longer than ',
                    maxDimensionBytes,
                    ' bytes: ',
                    truncatedDimensions.join(', '),
                    ' (see http://h.a/edapdimensionmapping for more info)'
                ].join(''));
            }
        };


        /**
         *  Setter for a UA Custom Dimension
         *
         *  @example
         *    var ua = ua.setUADimension('dimension1', '1234');
         *
         *  @example
         *    var ua = ua.setUADimension(2, '123456');
         *
         *  @params {Object} [key] - String or Number of the dimension
         *  @params {String} [value] - Value to assign to the key
         */
        this.setUADimension = function(key, value) {
            var config = this.getConfig(),
                maxDimensionBytes = config.maxDimensionBytes;

            if (typeof key === 'number') {
                key = this.dimName(key);
            }

            value = this.truncateDimensionByUTF8Bytes(value, maxDimensionBytes);

            this.setUAParam(key, value);
        };


        /**
         *  Setter for a UA Custom Dimensions (bulk setting)
         *
         *  @example
         *    var ua = ua.setUADimensions({1: 'bob', 2: 'fred'});
         *
         *  @params {Object} [dimMap] - Key/Value object of dimension -> value
         */
        this.setUADimensions = function(dimMap) {
            var dimKey;

            for (dimKey in dimMap) {
                /* istanbul ignore else  */
                if (dimMap.hasOwnProperty(dimKey)) {
                    // In an object all the keys will be a string so parseInt it to see if it should be an int
                    if (!isNaN(parseInt(dimKey, 10))) {
                        dimKey = parseInt(dimKey, 10);
                    }
                    this.setUADimension(dimKey, dimMap[dimKey]);
                }
            }
        };


        /**
         *  Setter for any parameter on the UA object
         *
         *  @example
         *    var ua = ua.setUAParam('appversion', '1.2.3');
         *
         *  @params {String} [key] - Name of the parameter
         *  @params {String} [value] - Value to assign to the key
         */
        this.setUAParam = function(key, value) {
            var ua = this.getUA();

            ua(this.buildUaFunctionName('set'), key, value);
        };


        /**
         *  Loads a UA plugin
         *
         *  @example
         *    ua.loadPligin('displayfeatures');
         *
         *  @param {*} [arguments] - arguments to be apply()'d to ua require function
         */
        this.loadPlugin = function() {
            var data = [this.buildUaFunctionName('require')],
                ua = this.getUA(),
                i,
                len;

            for (i = 0, len = arguments.length; i < len; i++) {
                data.push(arguments[i]);
            }

            ua.apply(ua, data);
        };


        /**
         *  Inserts the Universal Analytics script into the DOM
         *  It will not insert the script twice as it is using the DomHelpers.loadScript()
         *
         *  @example
         *    var wasScriptLoaded = ua.dropScript();
         *
         *  @returns {Boolean} - if the script was inserted of not
         */
        this.dropScript = function() {
            var that = this,
                conf = that.getConfig(),
                win = domHelpers.getWindow(),
                uaScriptToLoad = conf.uaScriptUrl,
                loadTimestamp;

            // If debugging is enabled then we should load the debug script
            if (debugHelpers.isInDebugMode(conf.edapScopeName)) {
                uaScriptToLoad = conf.uaDebugScriptUrl;
            }

            win.GoogleAnalyticsObject = conf.uaNS;
            win[conf.uaNS] = that.getUA() || function() {
                (win[conf.uaNS].q = that.getUA().q || []).push(arguments);
            };
            win[conf.uaNS].l = 1 * new Date();

            that.uaEdap.trigger('ua.load');
            loadTimestamp = domHelpers.getMsFromEpoch();

            win[conf.uaNS](function() {
                var currentTimestamp = domHelpers.getMsFromEpoch(),
                    loadtime = (currentTimestamp - loadTimestamp);

                that.uaEdap.trigger('ua.did.load', {
                    loadtime: '' + loadtime
                });
            });

            // Insert the script into the DOM
            return domHelpers.loadScript(uaScriptToLoad, conf.scriptId);
        };


        /**
         *  UA-specific "extend"-like function when it comes to options on a "send" command.
         *  Mostly it just makes sure that all the keys in defaultOpts are copied into passedOpts.
         *  The only special case is hitCallback and there is logic to make sure that the
         *  defaultOpts.hitCallback is always called event if there is already a passedOpts.hitCallback.
         *
         *  @example
         *    var defaultOpts = {
         *          nonInteraction: true,
         *          hitCallback: function() {}
         *        },
         *        passedOpts = {
         *          metric1: 1,
         *          hitCallback: function() {}
         *        }
         *    var mergedOpts = ua.mergeOptions(defaultOpts, passedOpts);
         *
         *  @param {Object} [defaultOpts] - Options to make sure exist in the return value
         *  @param {Object} [passedOpts] - Existing options that defaultOpts are merged into
         *  @returns {Object} - The final listed of merged options
         */
        this.mergeOptions = function(defaultOpts, passedOpts) {
            var that = this,
                passedHitCallback,
                i;

            // This is dynamically building the hitCallback function in order to closure in the original
            // callback function pointer as it loops over all the options. This is a bit of overkill since
            // this function will only be run once when the key is "hitCallback", but this will shut up ESLint.
            function saveHitCallback(callback) {
                return function() {
                    try {
                        callback.apply(callback);
                    } catch (err) {
                        that.uaEdap.error(err);
                    } finally {
                        defaultOpts.hitCallback();
                    }
                };
            }

            // Loop through the default opts and set them if not already set.
            for (i in defaultOpts) {
                /* istanbul ignore else  */
                if (defaultOpts.hasOwnProperty(i)) {
                    if (passedOpts.hasOwnProperty(i)) {
                        // If we get a hitCallback set then we should execute it and then execute our defaultOpts.hitCallback
                        if (i === 'hitCallback' && typeof passedOpts[i] === 'function') {
                            passedHitCallback = passedOpts[i];

                            passedOpts[i] = saveHitCallback(passedHitCallback);
                        }
                    } else {
                        passedOpts[i] = defaultOpts[i];
                    }
                }
            }

            return passedOpts;
        };


        /**
         * Handles extracting custom dimensions into event-level options for local CD override
         *
         * @param  {Object} [adlData]    - The data to use for dimensions
         * @param  {Object} [opts]       - The options object to override
         * @param  {Object} [configOpts] - Config options for how to send a hit
         *         skipExtractDimensions - true if we should *not* re-evalutate Custom Dimensions for this event
         * @param  {Object} [tracker]    - The UA tracker object
         * @return {Object}              - The modified opts object
         */
        this.setDimensionsInOpts = function(adlData, opts, configOpts, tracker) {
            var that = this,
                adl,
                dimensions;

            if (adlData) {
                adl = new ADL(adlData);

                configOpts = configOpts || {};

                // set 'page' in the opts if we have an override
                opts.page = that.getPageUrl(adl);

                // setup the Custom Dimensions if we have data and don't have event CDs disabled
                if (!configOpts.skipExtractDimensions) {
                    dimensions = that.buildCustomDimensions(adl, tracker);

                    opts = that.mergeOptions(opts, dimensions);
                }
            }

            return opts;
        };


        /**
         *  Calls a function with the UA 'tracker' parameter
         *  The initial call will get the tracker asynchronously and cache it. Subsequent calls will occur synchronously.
         *
         *  @example
         *    function funcNeedsTracker(tracker) {...}
         *    ua.callWithTracker(funcNeedsTracker);
         *
         *  @param {Function} [cb] - callback to be called within the UA closure. It will be passed a single UA tracker parameter
         */
        this.callWithTracker = function(cb) {
            var that = this,
                ua = that.getUA(),
                trackerName = that.getConfig().uaTrackerName;

            if (!uaTracker) {
                ua.apply(that, [function() {
                    // ua and window.ua are NOT the same here
                    uaTracker = that.getUA().getByName(trackerName);

                    cb(uaTracker);
                }]);
            } else {
                cb(uaTracker);
            }
        };


        /**
         * Resets the cached UA tracker object to undefined
         */
        this.resetTrackerCache = function() {
            uaTracker = undefined;
        };


        /**
         *  Checks required fields for ua functions. Otherwise, these would silently fail unless using the debug script
         *
         *  @param  {String} uaHitType - The UA hit type (e.g. event, timing) that we're checking for
         *  @param  {Object} fieldsObj - Key/value pairs of a field name and its given value
         *  @return {Boolean} - True if all keys pass validation, false otherwise
         */
        this.checkUaHitFields = function(uaHitType, fieldsObj) {
            var that = this,
                currentVal,
                key;

            for (key in fieldsObj) {
                /* istanbul ignore else */
                if (fieldsObj.hasOwnProperty(key)) {
                    currentVal = fieldsObj[key];

                    // validate that any values for UA's 'value' field are numeric (could be string or number)
                    // otherwise, check that the values are strings
                    if (key === 'value') {
                        if (isNaN(parseFloat(currentVal, 10))) {
                            that.uaEdap.error('UA ' + uaHitType + ' requires ' + key + ' to be numeric');
                            return false;
                        }
                    } else if (typeof currentVal !== 'string') {
                        that.uaEdap.error('UA ' + uaHitType + ' requires ' + key + ' to be defined string');
                        return false;
                    }
                }
            }

            return true;
        };


        /**
         *  Sends a UA event with options and overridden Custom Dimensions
         *
         *  @example
         *    var sendEventDoneable = ua.sendEvent('myCategory', 'myAction', 'myLabel', mValue, opts);
         *
         *  @param {String} [category] - category to send for this event
         *  @param {String} [action] - action to send for this event
         *  @param {String} [label] - label to send for this event
         *  @param {Number} [value] - numeric value to send for this event
         *  @param {Object} [uaOpts] - UA options that can be set when when sending an event
         *                           note: setting any dimension values will override anything extracted from adlData
         *  @param {Object} [adlData] - ADL data to be used to fill the Custom Dimensions
         *  @param {Object} [configOpts] - config options for how to send the event
         *         skipExtractDimensions - true if we should *not* re-evalutate Custom Dimensions for this event
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendEvent = function(category, action, label, value, uaOpts, adlData, configOpts) {
            var that = this;

            function sendUaEvent(tracker) {
                return new Doneable(function(done) {
                    var sendData = [
                            that.buildUaFunctionName('send'),
                            'event',
                            category,
                            action,
                            label,
                            value
                        ],
                        ua = that.getUA(),
                        defaultOpts = {
                            'nonInteraction': true,
                            'hitCallback': function() {
                                done();
                            }
                        },
                        opts = defaultOpts;

                    opts = that.setDimensionsInOpts(adlData, opts, configOpts, tracker);

                    // finally, apply any passed-in opts overrides
                    if (typeof uaOpts === 'object') {
                        opts = that.mergeOptions(opts, uaOpts);
                    }

                    sendData.push(opts);

                    ua.apply(ua, sendData);

                    that.logUAAction('Event', sendData);
                }, {
                    error: function(err) {
                        that.uaEdap.error(err);
                    }
                });
            }

            return new Doneable(function(done) {
                that.checkUaHitFields('event', {
                    category: category,
                    action: action
                });

                that.callWithTracker(function(tracker) {
                    sendUaEvent(tracker).then(done);
                });
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         *  Sends a UA Pageview
         *
         *  @example
         *    var sendPageviewDoneable = ua.sendPageview(window.analyticsdatalayer, opts);
         *
         *  @param {Object} [adlData] - ADL data to be used to fill the Custom Dimensions
         *  @param {Object} [uaOpts] - UA options that can be set when when sending a pageview
         *  @param {Object} [configOpts] - config options for how to send the pageview
         *         skipGlobalExtractDimensions - true if we should not call the global "set" for Custom Dimensions
         *                                       Dimensions will still be evaluated based on adlData for this hit,
         *                                       which is useful for in-page VPVs.
         *         pagetypeOverride            - String to override "pagetype" with
         *         pageurlOverride             - URL to override "pageurl" (and the resulting pageview's "page" value) with
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendPageview = function(adlData, uaOpts, configOpts) {
            var that = this;

            return new Doneable(function(done) {
                that.callWithTracker(function(tracker) {
                    var sendData = [
                            that.buildUaFunctionName('send'),
                            'pageview'
                        ],
                        ua = that.getUA(),
                        defaultOpts = {
                            'hitCallback': function() {
                                done();
                            }
                        },
                        opts = defaultOpts,
                        adl,
                        dimensions,
                        pageUrl;

                    configOpts = configOpts || {};

                    // Set the ADL variable for this pageview
                    adlData = adlData || that.globalADL.get();

                    // clone so we can modify without changing the data object that gets passed to other EDAP listeners
                    adlData = edapHelpers.clone(adlData);
                    adl = new ADL(adlData);

                    if (configOpts.pagetypeOverride) {
                        adl.set('pagetype', configOpts.pagetypeOverride);
                    }

                    if (configOpts.pageurlOverride) {
                        adl.set('pageurl', configOpts.pageurlOverride);
                    }

                    pageUrl = that.getPageUrl(adl);

                    sendData.push(pageUrl);

                    // Setup the Custom Dimensions - put them into opts if option is set, otherwise "set" them by default
                    dimensions = that.buildCustomDimensions(adl, tracker, {
                        // on real pageview (not VPVs) log an error if we have to truncate any dimensions
                        shouldLogTruncation: !configOpts.skipGlobalExtractDimensions
                    });
                    if (configOpts.skipGlobalExtractDimensions) {
                        opts = that.mergeOptions(opts, dimensions);
                    } else {
                        that.setUADimensions(dimensions);
                    }

                    if (typeof uaOpts === 'object') {
                        opts = that.mergeOptions(opts, uaOpts);
                    }

                    sendData.push(opts);

                    // Send the pageview
                    ua.apply(ua, sendData);

                    // Add a debug log
                    that.logUAAction('Pageview', sendData);
                });
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         * DRY function to build interaction VPV pagetype in cases where there isn't preexisting special logic (such as "booking")
         *
         * @param  {String} pagetype The original pagetype of the page
         * @return {String}          The pagetype to be used on an interaction VPV
         */
        this.buildInteractionVpvPagetype = function(pagetype) {
            return pagetype + ' (vpv)';
        };


        /**
         * Thin wrapper for sendPageview that sets options for in-page interaction VPVs
         *
         * @param  {Object} [data] - The ADL data to be used for filling in custom dimensions
         * @param  {Object} [vpvUrl] - The url to send for this interaction pageview
         * @param  {Object} [vpvPagetype] - The pagetype to override for this interaction pageview
         * @param  {Object} [uaOpts] - UA options that can be set for a pageview (e.g. dimensions, hitCallback, etc.)
         * @return {Doneable} - Doneable returned from sendPageview() - will be resolve()'d when the pageview's hitCallback is triggered
         */
        this.sendInteractionPageview = function(data, vpvUrl, vpvPagetype, uaOpts) {
            return this.sendPageview(
                data,
                uaOpts,
                {
                    pageurlOverride: vpvUrl,
                    pagetypeOverride: vpvPagetype,
                    skipGlobalExtractDimensions: true
                }
            );
        };


        /**
         *  Sends a UA Timing event
         *
         *  @example
         *    var sendTimingDoneable = ua.sendTiming('myCategory', 'timingName', 100, 'myLabel', opts);
         *
         *  @param {String} [category] - category to send for this event
         *  @param {String} [name] - action to send for this event
         *  @param {Number} [value] - time in ms for this event
         *  @param {String} [label] - label to send for this event
         *  @param {Object} [opts] - UA options that can be set when when sending an event
         *  @param {Object} [adlData] - data for Custom Dimensions
         *  @param {Object} [configOpts] - config options for how to send the event
         *         skipExtractDimensions - true if we should *not* re-evalutate Custom Dimensions for this timing event
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendTiming = function(category, name, value, label, opts, adlData, configOpts) {
            var that = this;

            return new Doneable(function(done) {
                that.checkUaHitFields('timing', {
                    category: category,
                    name: name,
                    value: value
                });

                that.callWithTracker(function(tracker) {
                    var sendData = [
                            that.buildUaFunctionName('send'),
                            'timing',
                            category,
                            name,
                            value,
                            label
                        ],
                        ua = that.getUA(),
                        defaultOpts = {
                            'nonInteraction': true,
                            'hitCallback': function() {
                                done();
                            }
                        };

                    if (typeof opts !== 'object') {
                        opts = defaultOpts;
                    } else {
                        opts = that.mergeOptions(defaultOpts, opts);
                    }

                    opts = that.setDimensionsInOpts(adlData, opts, configOpts, tracker);

                    sendData.push(opts);

                    ua.apply(ua, sendData);

                    that.logUAAction('Timing', sendData);
                });
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         *  Sends a UA Social event
         *
         *  @example
         *    var sendSocialDoneable = ua.sendSocial('facebook', 'like', 'myTarget', opts);
         *
         *  @param {String} [network] - social network to send for this event
         *  @param {String} [action] - action to send for this event
         *  @param {String} [target] - target to send for this event
         *  @param {Object} [opts] - UA options that can be set when when sending an event
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendSocial = function(network, action, target, opts) {
            var that = this;

            return new Doneable(function(done) {
                var sendData = [
                        that.buildUaFunctionName('send'),
                        'social',
                        network,
                        action,
                        target
                    ],
                    ua = that.getUA(),
                    defaultOpts = {
                        'nonInteraction': true,
                        'hitCallback': function() {
                            done();
                        }
                    };

                if (typeof opts !== 'object') {
                    sendData.push(defaultOpts);
                } else {
                    sendData.push(that.mergeOptions(defaultOpts, opts));
                }

                ua.apply(ua, sendData);

                that.logUAAction('Social', sendData);
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         *  Sends a UA Exception event
         *
         *  @example
         *    var sendExceptionDoneable = ua.sendException(description, isFatal, opts);
         *
         *  @param {String} [description] - description of exception
         *  @param {Boolean} [isFatal] - if the exception was a fatal error
         *  @param {Object} [opts] - UA options that can be set when when sending an event
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendException = function(description, isFatal, opts) {
            var that = this;

            return new Doneable(function(done) {
                var sendData = [
                        that.buildUaFunctionName('send'),
                        'exception'
                    ],
                    ua = that.getUA(),
                    defaultOpts = {
                        'exDescription': description,
                        'exFatal': !!isFatal,
                        'nonInteraction': true,
                        'hitCallback': function() {
                            done();
                        }
                    };

                if (typeof opts !== 'object') {
                    sendData.push(defaultOpts);
                } else {
                    sendData.push(that.mergeOptions(defaultOpts, opts));
                }

                ua.apply(ua, sendData);

                that.logUAAction('Exception', sendData);
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         *  Adds a UA Ecommerce Transaction
         *
         *  @example
         *      ua.addEcommTransaction('12345', 'Homeaway', '123.33', '33.00', '1.23');
         *
         *  @param {String} [id] - ID of the Ecommerce transaction
         *  @param {String} [affiliation] - What this Ecommerce transaction is affilated with
         *  @param {String} [total] - Total price including shippig and tax
         *  @param {String} [shipping] - Shipping cost for this transaction
         *  @param {String} [tax] - Taxes for this transaction
         */
        this.addEcommTransaction = function(id, affiliation, total, shipping, tax) {
            var ua = this.getUA(),
                transData = {
                    id: id,
                    affiliation: (affiliation || undefined),
                    revenue: (total || undefined),
                    shipping: (shipping || undefined),
                    tax: (tax || undefined)
                };

            ua(this.buildUaFunctionName('ecommerce:addTransaction'), transData);

            this.logUAAction('Ecomm:addTransaction', transData);
        };


        /**
         *  Adds a UA Ecommerce Item
         *
         *  @example
         *      ua.addEcommItem('12345', 'PDP', 'PDP_2', 'VAS', '123.33', '1');
         *
         *  @param {String} [id] - ID of the Ecommerce transaction
         *  @param {String} [name] - Name of the item being added
         *  @param {String} [sku] - SKU of the item being added
         *  @param {String} [category] - Category of the item being added
         *  @param {String} [price] - Price per unit of the item being added
         *  @param {String} [quantity] - Quantity of items
         */
        this.addEcommItem = function(id, name, sku, category, price, quantity) {
            var ua = this.getUA(),
                itemData = {
                    id: id,
                    name: name,
                    sku: (sku || undefined),
                    category: (category || undefined),
                    price: (price || undefined),
                    quantity: (quantity || undefined)
                };

            ua(this.buildUaFunctionName('ecommerce:addItem'), itemData);

            this.logUAAction('Ecomm:addItem', itemData);
        };

        /**
         *  Clears ecommerce cart
         *
         *  @example
         *    ua.clearEcommCart();
         */
        this.clearEcommCart = function() {
            var ua = this.getUA();

            ua(this.buildUaFunctionName('ecommerce:clear'));

            this.logUAAction('Ecomm:clearCart');
        };

        /**
         *  Sends a UA Ecommerce transaction
         *
         *  Note: There is a business logic interface function defined in ecomm.js
         *
         *  @example
         *      ua.sendEcomm();
         *
         *  @param {Object} [opts] - UA options that can be set when when sending an event
         *  @returns {Doneable} - Doneable object that will be resolve()'d when hitCallback is triggered
         */
        this.sendEcomm = function(opts) {
            var that = this;

            return new Doneable(function(done) {
                var sendData = [that.buildUaFunctionName('ecommerce:send')],
                    ua = that.getUA(),
                    defaultOpts = {
                        'nonInteraction': true,
                        'hitCallback': function() {
                            done();
                        }
                    };

                if (typeof opts !== 'object') {
                    sendData.push(defaultOpts);
                } else {
                    sendData.push(that.mergeOptions(defaultOpts, opts));
                }

                ua.apply(ua, sendData);

                that.logUAAction('Ecomm:Send', sendData);
            }, {
                error: function(err) {
                    that.uaEdap.error(err);
                }
            });
        };


        /**
         *  Gets the UA profile data for a site based on the href of the page
         *
         *  @example
         *      var profileData = ua.getUAProfileData();
         *
         *  @returns {Array} - in the format: [UA ProfileId, Cookie Domain, Cookie Path, Profile Regex, Profile Regex Weight]
         */
        this.getUAProfileData = function() {
            var config = this.getConfig(),
                adl = new ADL(),
                appName = adl.get('appname'),
                luxuryParentMonikers = {
                    luxury_us: 'homeaway_us',
                    luxury_uk: 'homeaway_uk',
                    luxury_de: 'homeaway_de'
                },
                profileName,
                profileData,
                errStr;

            // If they have set the ADL.analyticsbrand then we should honor it, if it is valid
            if (adl.isSet('analyticsbrand') && config.supportedSites[adl.get('analyticsbrand')]) {
                profileName = adl.get('analyticsbrand');
            }

            // We special case this since we can't use the url to determine the profile name
            if (appName === 'ums-cas') {
                // if ADL.analyticsbrand was set
                if (profileName === undefined) {
                    profileName = adl.get('monikerbrand');
                }

                // if we're on a luxury site, use the parent site's monikerbrand instead
                if (luxuryParentMonikers[profileName]) {
                    profileName = luxuryParentMonikers[profileName];
                }

                profileData = config.supportedSites[profileName];

                if (profileData !== undefined) {
                    // Override some of the data for CAS
                    profileData[1] = domHelpers.getHostname();
                    profileData[2] = '/';
                } else {
                    profileName = '';
                }

            // All other brands we should extract the profilename based on the href
            } else {
                // if ADL.analyticsbrand was set
                if (profileName === undefined) {
                    profileName = this.getMonikerBrandByHref(domHelpers.getHref());
                }

                profileData = config.supportedSites[profileName];

                // Sometimes we need to set the cookie domain at runtime (ex. expedia-stage.homeaway.com)
                if (profileData instanceof Array && profileData[1] === null) {
                    profileData[1] = domHelpers.getHostname();
                }
            }

            if (profileName === '') {
                errStr = 'UA init: Cannot find matching profile name';
                this.uaEdap.error(errStr);

                return null;
            }

            return profileData;
        };


        /**
         *  Builds the standardized Regex and Regex Weight used in getMonikerBrandByHref()
         *
         *  @example
         *      var siteRegexInfo = ua.buildSiteRegex(['UA-123', 'homeaway.com', '/', null, null]);
         *
         *  @param {Array} [siteData] - in the format: [UA ProfileId, Cookie Domain, Cookie Path, Profile Regex, Profile Regex Weight]
         *  @returns {Object} - in the format: [profileRegExp, profileWeight] or null
         */
        this.buildSiteRegex = function(siteData) {
            var COOKIE_DOMAIN_IDX = 1,
                PROFILE_REGEX_IDX = 3,
                PROFILE_REGEX_WEIGHT_IDX = 4,
                PROFILE_SKIP = 5,
                profileRegExpStr,
                profileRegExp,
                profileWeight,
                domainParts,
                errStr;

            if (!(siteData instanceof Array)) {
                errStr = 'UA buildSiteRegex is expecting an array';
                this.uaEdap.error(errStr);
                return null;
            }

            // If this profile is marked as a skip then we can't build a regex and we should just return null.
            if (siteData[PROFILE_SKIP]) {
                return null;
            }

            if (siteData[PROFILE_REGEX_IDX] === null) {
                if (siteData[COOKIE_DOMAIN_IDX] !== null) {
                    profileRegExpStr = siteData[COOKIE_DOMAIN_IDX];
                    profileRegExpStr = profileRegExpStr.replace(/[.]/gi, '[.]');
                    profileRegExp = new RegExp('^https?:[/][/]([^/]+[.])?' + profileRegExpStr + '(:\\d+)?([/].*$|$)');

                // If we don't have a domain and a regex then we can't build the Regex
                } else {
                    errStr = 'UA buildSiteRegex: cannot build regex since domain and regex are null';
                    this.uaEdap.error(errStr);
                    return null;
                }
            } else {
                profileRegExp = siteData[PROFILE_REGEX_IDX];
            }

            if (siteData[PROFILE_REGEX_WEIGHT_IDX] === null) {
                domainParts = siteData[COOKIE_DOMAIN_IDX].split('.');
                profileWeight = domainParts.length;
            } else {
                profileWeight = siteData[PROFILE_REGEX_WEIGHT_IDX];
            }

            return [profileRegExp, profileWeight];
        };


        /**
         *  Gets the moniker_brand-esque value from the href
         *
         *  @example
         *      var analyticsBrand = ua.getMonikerBrandByHref();
         *
         *  @param {String} [href] - The href to be used to determine the analytics brand
         *  @returns {String} - analytics brand string based on the href
         */
        this.getMonikerBrandByHref = function(href) {
            var sites = this.getConfig().supportedSites,
                matchedSite = '',
                matchedSiteWeight = 0,
                i,
                siteRegexData,
                errStr;

            for (i in sites) {
                /* istanbul ignore else  */
                if (sites.hasOwnProperty(i)) {
                    siteRegexData = this.buildSiteRegex(sites[i]);

                    if (siteRegexData !== null && href.match(siteRegexData[0]) !== null) {
                        if (siteRegexData[1] > matchedSiteWeight) {
                            matchedSiteWeight = siteRegexData[1];
                            matchedSite = i;
                        } else if (siteRegexData[1] === matchedSiteWeight) {
                            errStr = 'UA getMonikerBrandByHref: ' + href + ' matched ' + matchedSite + ' and ' + i;
                            this.uaEdap.error(errStr);
                            return '';
                        }
                    }
                }
            }

            return matchedSite;
        };


        /**
         * Get the appropriate 'page' value for a UA hit
         *
         * @param  {ADLHelpers} [adl] - ADLHelpers instance to be used to determine 'page' URL
         * @return {String} - The 'page' URL for the hit or null if there should be no override
         */
        this.getPageUrl = function(adl) {
            var that = this,
                config = that.getConfig(),
                pageUrl = null,
                pagename;

            // Extract/Normalize the pagename
            pagename = adl.get('pagename');
            if (typeof pagename === 'string') {
                pagename = pagename.toLowerCase();
            }

            // Setting the pageview URL in order of preference:
            //  1. ADL.pageurl
            //  2. VPV based on pagename
            //  3. domHelpers.getPage() - the path and query string of document.location
            if (adl.isSet('pageurl')) {
                pageUrl = that.vpvizePath(adl.get('pageurl'));
            } else if (pagename && config.pagenameToVPV.hasOwnProperty(pagename)) {
                pageUrl = that.vpvizePath(config.pagenameToVPV[pagename]);
            } else {
                pageUrl = domHelpers.getPage();
            }

            return pageUrl;
        };


        /**
         *  VPV-izes a path url... aka, adds a VPV prefix to all VPV paths
         *
         *  @example
         *    var vpvpath = ua.vpvizePath('/gd/rm/messages');
         *
         *  @param {String} [path] - Pathname to prepend the VPV prefix
         *  @returns {Object} - The VPV-ized pathname or null
         */
        this.vpvizePath = function(path) {
            var vpvPrefix = '/vpv',
                ret = path;

            if (typeof path === 'string') {
                if (path.indexOf(vpvPrefix + '/') === 0) {
                    ret = path;
                } else if (path.indexOf('/') === 0) {
                    ret = vpvPrefix + path;
                } else {
                    ret = vpvPrefix + '/' + path;
                }

                return ret;
            }

            return null;
        };


        /**
         *  Sets the global UA Custom Dimensions
         *
         *  @example
         *      ua.buildCustomDimensions(window.analyticsdatalayer);
         *
         *  @param {ADLHelpers} [adl] - ADLHelpers instance to be used to set the Custom Dimensions
         *  @param {Object} [tracker] - UA tracker object
         *  @param {Object} [opts]    - Options for building CDs
         *         shouldLogTruncation - true if we should log an error for truncated dimension values
         *  @returns {Object} - Key:Value pairs of the format {dimensionX: valueX, dimensionY, valueY, ...}
         */
        this.buildCustomDimensions = function(adl, tracker, opts) {
            var that = this,
                conf = that.getConfig(),
                dex = conf.dimensionExtractors,
                truncatedDimensions = [], // will push objects with dimension name, original value, and truncated value
                retDims = {},
                dim,
                value;

            function setDim(dimensionNum, dimensionVal) {
                var dimensionName = that.determineDimName(dimensionNum),
                    val = that.truncateDimensionByUTF8Bytes(dimensionVal, conf.maxDimensionBytes);

                if (val !== dimensionVal) {
                    truncatedDimensions.push({
                        dimName: dimensionName,
                        originalValue: dimensionVal,
                        truncatedValue: val
                    });
                }

                retDims[dimensionName] = val;
            }

            opts = opts || {};

            if (!(adl instanceof ADL)) {
                that.uaEdap.error('buildCustomDimensions: called without ADL instance');
                return retDims;
            }

            if (typeof tracker !== 'object') {
                that.uaEdap.error('buildCustomDimensions: called without tracker');
                return retDims;
            }

            for (dim in dex) {
                /* istanbul ignore else  */
                if (dex.hasOwnProperty(dim)) {
                    value = that.getDimensionValue(dim, adl, tracker);

                    if (adl.shouldSet(value)) {
                        setDim(dim, value);
                    }
                }
            }

            // Assign the Proctor Test data to the correct Dimension
            // dim is of the format [CD # to use, ADL.proctor string]
            dim = this.extractors.getProctorDimension(adl);
            if (adl.shouldSet(dim[1])) {
                setDim(dim[0], dim[1]);
            }

            if (opts.shouldLogTruncation) {
                that.logTruncationErrors(truncatedDimensions);
            }

            return retDims;
        };


        /**
         * Use the dimension extractor to get the value for a given dimension
         *
         * @param  {Number}     dimNum  The dimension to determine the value for
         * @param  {ADLHelpers} [adl]   ADLHelpers instance to be used to set the Custom Dimensions
         * @param  {Object} [tracker]   UA tracker object
         * @return {String}             The value for the passed dimesnion slot
         */
        this.getDimensionValue = function(dimNum, adl, tracker) {
            var conf = this.getConfig(),
                dex = conf.dimensionExtractors,
                ext = dex[dimNum],
                extType = typeof ext,
                ret;

            if (dex.hasOwnProperty(dimNum)) {
                if (extType === 'string') {
                    ret = adl.get(ext);
                } else if (extType === 'function') {
                    ret = ext.apply(this, [adl, this, tracker]);

                // log an EDAP error, but don't throw a real error so that *some* data may get sent
                } else {
                    this.uaEdap.error('getDimensionValue: unexpected type "' + extType + '" for dimension ' + dimNum);
                }
            } else {
                this.uaEdap.error('getDimensionValue: attempted to lookup a dimension not present in the UA config');
            }

            if (ret !== null && ret !== undefined && typeof ret.toString === 'function') {
                ret = ret.toString();
            }

            return ret;
        };

        /**
         * Checks to see if this is an existing or new user based on existence of
         * the UA ("_ga") cookie and does the following:
         *     - sets property 'isNewUser' in the UA scope data
         *     - returns the whether this is a new user
         *
         * @return {Boolean} true if new user (no cookie), false otherwise
         */
        this.checkIfNewUser = function() {
            var config = this.getConfig(),
                uaCookieName = config.uaCookieName,
                uaScopeNamespace = config.edapScopeName,
                uaCookie = domHelpers.getCookie(uaCookieName),
                scopeData = edap.getScopeData(uaScopeNamespace);

            scopeData.isNewUser = (uaCookie === null);

            return scopeData.isNewUser;
        };

        /**
         *  Initializes the UA interface wrapper
         *
         *  @example
         *      ua.init();
         *
         *  @return {Boolean} - true if UA was successfully initted, false otherwise
         */
        this.init = function() {
            var uaScope = edap.getScopeInfo()[this.getConfig().edapScopeName],
                config = this.getConfig(),
                uaInitData = {},
                retVal = false,
                clientId,
                profileData,
                ua;

            if (uaScope) {
                // Store the UA EDAP scope
                this.uaEdap = uaScope.instance;
            } else {
                edap.error('Could not find UA EDAP Scope... aborting UA init()');
                return retVal;
            }

            profileData = this.getUAProfileData();

            // If we can't find the profile then we don't init UA
            if (profileData === null) {
                // no need to log this error since getUAProfileData() already did
                return retVal;
            }

            // If the profile is for local development, don't init UA
            if (profileData[0] === 'DEV') {
                return retVal;
            }

            // Add the analytics.js script and setup the 'ua' namespace
            this.dropScript();

            // Have to initialize AFTER dropScript() since it does the globals setup
            ua = this.getUA();

            if (!ua) {
                this.uaEdap.error('Cannot init UA: Unable to get UA instance');
                return retVal;
            }
            retVal = true;

            // Pull the cross domain query param and try to parse it as a UA cookie in order to set the clientID
            // for cross-domain tracking of user and sessions.
            clientId = this.parseClientIdFromUACookie(domHelpers.getSearchParam(config.uaCrossDomainParam));

            // Setup the UA instance data
            uaInitData = {
                'cookieDomain': profileData[1],
                'cookiePath': profileData[2]
            };

            // If we have were passed a clientId then we need to assign it as the UA clientId
            if (typeof clientId === 'string') {
                uaInitData.clientId = clientId;
            }

            // If the page has a publicUUID then we set it as the UA userid for cross device tracking
            // NOTE: This relies on the ADL.publicuuid being set in the source by the server. If an app dynamically sets this there is a potential for a race condition.
            if (this.globalADL.isSet('publicuuid')) {
                uaInitData.userId = this.globalADL.get('publicuuid');
            }

            uaInitData.name = config.uaTrackerName;

            uaInitData.trackingId = profileData[0];

            // Create the UA tracker
            ua('create', uaInitData);

            this.setUAParam('transport', 'beacon');

            // Enable GDN Display Features tracking
            this.loadPlugin('displayfeatures');

            return retVal;
        };
    }

    return new UA();
});

