define('ua/listeners/quote',[
    'ua/ua-wrapper',
    'ua/ua-config',
    'adlhelpers',
    'domhelpers',
    'edaphelpers'
], function(ua, config, ADL, domHelpers, edapHelpers) {
    'use strict';

    /**
     * EVENTS:
     *  quote.attempt
     *  quote.success
     *  quote.failure
     *
     */
    function setupListeners(edap) {
        function trackQuote(data, quoteType, done) {
            // copy data obj since we don't want the following changes affecting other EDAP listeners
            var dataCopy = edapHelpers.clone(data);

            // Override pagetype, pagename, pageurl for all of these on the VPV for historical reasons
            // We do this to make sure only 1 PV has a pagetype='property' for reporting
            if (dataCopy.pagetype === 'property') {
                dataCopy.pagetype = 'booking';
                dataCopy.pagename = 'traveler ' + quoteType;
                dataCopy.pageurl = config.staticVPVs[quoteType];
            } else {
                dataCopy.pagetype = dataCopy.pagetype + ' ' + quoteType;
                dataCopy.pagename = dataCopy.pagename + ' ' + quoteType;
                dataCopy.pageurl = domHelpers.getPage() + '/' + quoteType;
            }

            ua.sendInteractionPageview(dataCopy, dataCopy.pageurl).then(done); // don't need to pass pagetype since we set it above
        }

        edap.on('quote.attempt', function(data, setAsync, done) {
            setAsync();

            trackQuote(data, 'quoteattempt', done);
        });

        edap.on('quote.success', function(data, setAsync, done) {
            setAsync();

            trackQuote(data, 'quotesuccess', done);
        });

        edap.on('quote.failure', function(data, setAsync, done) {
            setAsync();

            trackQuote(data, 'quotefailure', done);
        });
    }

    return {
        setupListeners: setupListeners
    };
});

