define('data-enrichment/pageid-helper',[
    'adlhelpers',
    'domhelpers',
    'edap'
], function(ADL, domHelpers, edap) {
    'use strict';

    function PageIdHelper() {
        var adl = new ADL(),
            storedIdKey = 'parentPageviewId',
            sessionStorageName = 'sessionStorage',
            localStorageName = 'localStorage',
            integrationName = 'hadoop',
            missingVal = '-1';

        /**
         * Getter for the parentPageviewIdObj object stored in the EDAP scope data
         *
         * Should have two properties:
         *  id - the parent pageview ID
         *  sessionid - the session ID for which id is relevant
         *
         * @return {Object} - The parentPageviewIdObj object from the EDAP scope data
         */
        function getParentPageviewIdObj() {
            return edap.getScopeData(integrationName).parentPageviewIdObj || {};
        }

        /**
         * Setter for the parentPageviewIdObj object stored in the EDAP scope data
         *
         * Should have two properties:
         *  id - the parent pageview ID
         *  sessionid - the session ID for which id is relevant
         *
         * @param {Object} newObj - Object to set as parentPageviewIdObj in EDAP scope data
         */
        function setParentPageviewIdObj(newObj) {
            edap.getScopeData(integrationName).parentPageviewIdObj = newObj;
        }

       /**
        *  Gets the data associated with the key in storage.
        *
        *  @param {String} key - The key the data is associated with*
        *  @param {Object} storageOpts - Options for configuring the storage engine
        *  @returns {Object} - The data object from storage associated with the key, or null
        *                      if the key is missing or an error occurs in decoding the data
        */
        this.getData = function(key, storageOpts) {
            var logger = edap.internal.getPersistentLogger();
            return logger.get(key, storageOpts);
        };


        /**
         * Gets data in the scope of the current tab
         *
         * @param {String} key - The key to store the data under
         * @returns {Object} - The data object from storage associated with the key, or null
         *                      if the key is missing or an error occurs in decoding the data
         */
        this.getTabSpecificData = function(key) {
            return this.getData(key, {
                storages: sessionStorageName
            });
        };


        /**
         * Gets data from cross-tab context
         *
         * @param {String} key - The key to store the data under
         * @returns {Object} - The data object from storage associated with the key, or null
         *                      if the key is missing or an error occurs in decoding the data
         */
        this.getCrossTabData = function(key) {
            return this.getData(key, {
                storages: localStorageName
            });
        };


        /**
         * Gets data from the scope of the current tab, then fall back to cross-tab if necessary
         *
         * @param {String} key - The key to store the data under
         * @returns {Object} - The data object from storage associated with the key, or null
         *                      if the key is missing or an error occurs in decoding the data
         */
        this.getDataWithFallback = function(key) {
            var data = this.getData(key, {
                storages: sessionStorageName
            });
            if (!data) {
                data = this.getData(key, {
                    storages: localStorageName
                });
            }
            return data;
        };


       /**
        * Sets a key-value pair in storage
        *
        * @param {String} key - The key to store the data under
        * @param {Object} value - The value to store at `key`
        * @param {Object} storageOpts - Options to pass about how to persist the data
        * @returns {Boolean} - true if the data was set, false if it was not
        */
        this.setData = function(key, value, storageOpts) {
            var logger = edap.internal.getPersistentLogger();
            return logger.set(key, value, storageOpts);
        };


        /**
         * Sets data in the scope of the current tab
         *
         * @param {String} key - The key to store the data under
         * @param {Object} value - The value to store at `key`
         */
        this.setTabSpecificData = function(key, value) {
            this.setData(key, value, {
                storages: sessionStorageName
            });
        };


        /**
         * Sets data for cross-tab use
         *
         * @param {String} key - The key to store the data under
         * @param {Object} value - The value to store at `key`
         */
        this.setCrossTabData = function(key, value) {
            this.setData(key, value, {
                storages: localStorageName
            });
        };


        /**
         * Encapsulates logic of fallback for determining session ID
         *
         * @param  {Object} data - The ADL Helpers data object to use for this session ID
         * @return {String} - The session ID
         */
        this.getSessionId = function(data) {
            // Fallback order: ADL.sessionid, 'has' cookie, '-1'
            var id;

            if (data.isSet('sessionid')) {
                return data.get('sessionid');
            }

            id = domHelpers.getCookie('has');

            if (data.shouldSet(id)) {
                return id;
            }

            return missingVal;
        };


        /**
         * Determines whether we should consider two session IDs to be a match
         *
         * @param  {String} id1 - The first session ID to compare
         * @param  {String} id2 - The second session ID to compare
         * @return {Boolean} - Whether the IDs were considdered to be a match
         */
        this.sessionIdsMatch = function(id1, id2) {
            return adl.shouldSet(id1) && adl.shouldSet(id2) && id1 === id2;
        };


        /**
        * Stores the current pageview ID
        *
        * @param {Object} data - The ADL Helpers data object to use
        */
        this.storeCurrentPageviewId = function(data) {
            var that = this,
                pageviewId = edap['public'].createUuid(),
                objToStore;

            // The currently stored pageview ID is the parent pageview ID. Before overwriting it,
            // we need to grab it for use with any events on this page.
            that.cacheParentPageviewId(pageviewId, data);

            // Write the current page's currentpageviewid to storage for use as the next page's parent
            // Need both tab-specific and cross-tab to support opening new tabs
            objToStore = {
                parentpageviewid: pageviewId,
                sessionid: that.getSessionId(data)
            };
            that.setTabSpecificData(storedIdKey, objToStore);
            that.setCrossTabData(storedIdKey, objToStore);

            // Set up an event listener for window focus to save the focused window's IDs to storage where they can be read by other tabs
            that.trackCurrentWindowIds();
        };


        /**
        * Retrieves and caches the parent pageview ID
        *
        * @param {String} pageviewId - The current pageview id from the ADL or auto-filled
        * @param {Object} data - The ADL Helpers data object to use
        */
        this.cacheParentPageviewId = function(pageviewId, data) {
            var that = this,
                idObj = that.getDataWithFallback(storedIdKey) || {},
                currentSessionId = that.getSessionId(data);

            if (!that.sessionIdsMatch(idObj.sessionid, currentSessionId)) {
                idObj.parentpageviewid = missingVal;
            }

            idObj.sessionid = currentSessionId;
            idObj.currentpageviewid = pageviewId;

            setParentPageviewIdObj(idObj);
        };


        /**
         * Initializes an event listener to set the currently focused tab's parent object into cross-tab storage
         */
        this.trackCurrentWindowIds = function() {
            var that = this,
                currentCrossTabStoredObj = that.getCrossTabData(storedIdKey),
                focusEventName = 'focus',
                currentTabSpecificStoredObj;

            // on focus, store focused window's stored ids from session storage into localStorage as well
            // so we can retieve it if someone opens a new tab
            function syncStorages() {
                currentTabSpecificStoredObj = that.getTabSpecificData(storedIdKey);
                that.setCrossTabData(storedIdKey, currentTabSpecificStoredObj);
            }

            // initialize our localStorage object for the case where new tabs are opened at the beginning of a
            // session without bluring/refocusing the current window/tab
            if (currentCrossTabStoredObj === null) {
                syncStorages();
            }

            // We don't want this added multiple times in a SPA - unregister before re-adding
            domHelpers.removeWindowEventListener(focusEventName, syncStorages);
            domHelpers.addWindowEventListener(focusEventName, syncStorages);
        };


        /**
        * Getter for the parent pageview ID
        * Checks that the ID is for the current session
        *
        * @param {Object} data - The ADL Helpers data object to use
        * @return {String} - The pageview ID of the previous page
        */
        this.getParentPageviewId = function(data) {
            var that = this,
                currentSessionId = that.getSessionId(data),
                parentPageviewIdObj = getParentPageviewIdObj();

            if (!that.sessionIdsMatch(parentPageviewIdObj.sessionid, currentSessionId)) {
                parentPageviewIdObj.parentpageviewid = missingVal;
            }

            return parentPageviewIdObj.parentpageviewid;
        };


        /**
        * Getter for the current pageview ID
        *
        * @return {String} - The pageview ID of the previous page
        */
        this.getCurrentPageviewId = function() {
            var parentPageviewIdObj = getParentPageviewIdObj();

            return parentPageviewIdObj.currentpageviewid;
        };
    }

    return new PageIdHelper();
});

