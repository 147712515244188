define('hadoop/listeners/test-exposure-handlers',['adlhelpers', 'domhelpers', 'edaphelpers', 'doneable'], function(ADL, domHelpers, edapHelpers, Doneable) {
    'use strict';

    var defaultEntityId = '-1';
    var cachedExposuresWithEntityId = {};
    var batchedExposureCalls = [];
    var testNameProperty = 'testname';
    var variantProperty = 'variant';
    var entityIdProperty = 'entityid';

    /**
     * Processes a 'test.exposure' event and send it to Hadoop
     *
     * @throws {TypeError} - if paremeters do not pass validation
     * @param {Object} [hadoop] - Instance of hadoop-wrapper
     * @param {Object} [edap] - Instance of edap or an EDAP Scope instance
     * @param {ADL} [adl] - Instance of an ADL model
     * @param {boolean} [pageviewTriggered] - True when a pageview has been triggered
     * @param {boolean} [finalize] - True when a pageview has been triggered and edap.trigger needs to be called
     * @returns {Doneable} - resolved when the Hadoop send() is complete, or if there was an error
     */
    function processTestExposure(hadoop, edap, adl, pageviewTriggered, finalize) {
        if (!edap || typeof edap.trigger !== 'function') {
            throw new TypeError('processTestExposure(): must pass in an EDAP instance');
        }

        return new Doneable(function(done) {
            if (!hadoop) {
                throw new TypeError('processTestExposure(): hadoop must be set');
            }

            if (!(adl instanceof ADL)) {
                throw new TypeError('processTestExposure(): adl must be an ADLHelpers instance');
            }

            // Push data into the array unless finalized
            if (!finalize) {
                if (!(typeof adl.get(variantProperty) === 'string' && adl.get(testNameProperty) && adl.get(variantProperty))) {
                    throw new ReferenceError('processTestExposure(): event data must contain testname and variant in string format');
                }

                if (adl.isSet(entityIdProperty)) {
                    if (cachedExposuresWithEntityId.hasOwnProperty(adl.get(entityIdProperty))) {
                        cachedExposuresWithEntityId[adl.get(entityIdProperty)][testNameProperty].push(adl.get(testNameProperty));
                        cachedExposuresWithEntityId[adl.get(entityIdProperty)][variantProperty].push(adl.get(variantProperty));
                    } else {
                        cachedExposuresWithEntityId[adl.get(entityIdProperty)] = {};
                        cachedExposuresWithEntityId[adl.get(entityIdProperty)][testNameProperty] = [adl.get(testNameProperty)];
                        cachedExposuresWithEntityId[adl.get(entityIdProperty)][variantProperty] = [adl.get(variantProperty)];
                    }
                } else {
                    if (cachedExposuresWithEntityId.hasOwnProperty(defaultEntityId)) {
                        cachedExposuresWithEntityId[defaultEntityId][testNameProperty].push(adl.get(testNameProperty));
                        cachedExposuresWithEntityId[defaultEntityId][variantProperty].push(adl.get(variantProperty));
                    } else {
                        cachedExposuresWithEntityId[defaultEntityId] = {};
                        cachedExposuresWithEntityId[defaultEntityId][testNameProperty] = [adl.get(testNameProperty)];
                        cachedExposuresWithEntityId[defaultEntityId][variantProperty] = [adl.get(variantProperty)];
                    }
                }
            } else {
                // Only trigger a test.exposure event if there's something to send
                if (Object.keys(cachedExposuresWithEntityId).length) {
                    edapHelpers.forOwn(cachedExposuresWithEntityId, function(value, key) {
                        /* istanbul ignore else */
                        if (value[testNameProperty].length && value[variantProperty].length) {
                            batchedExposureCalls.push(edap.trigger('test.exposure', {
                                testname: value[testNameProperty].join(','),
                                variant: value[variantProperty].join(','),
                                entityid: key
                            }));
                        }
                    });
                    Doneable.all(batchedExposureCalls).then(function() {
                        // Make sure the same data isn't sent repeatedly
                        cachedExposuresWithEntityId = {};
                        batchedExposureCalls = [];
                        done();
                    });
                } else {
                    // pageview has happened without anything to be done for test exposures
                    done();
                }
                return;
            }
            // Test for pageview having been triggered previously or fall through to done()
            if (pageviewTriggered === true) {
                hadoop.send('test.exposure', adl.get(), adl.isSetGet('sessionid'), adl.isSetGet('visitorid')).then(function() {
                    cachedExposuresWithEntityId = {};
                    done();
                });
                return;
            }
            done();
        }, {
            error: edap.error
        });
    }

    return {
        processTestExposure: processTestExposure
    };
});

